import React from "react"

import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const Books = props => {
  const { data } = props
  const { basicPythonCover } = data
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title="Meus livros - Python Básico" />
      <div className="container py-8">
        <div className="flex flex-wrap">
          <div className="w-full">
            <div className="md:w-12/12 mb-8">
              <h1 className="mb-4 font-bold text-3xl no-underline">
                Python Básico
              </h1>
              <p>
                Este livro falará de programação com Python. A meta é que seja
                um guia simples de ser lido e seguido, com exemplo e linguagem
                mais claros possível, sem enrolação. Nada de história do Python,
                filosofia da linguagem ou qualquer coisa do tipo.
              </p>
              <p className="mt-3">
                Este livro assume um conhecimento básico de utilização do
                computador de uma forma geral e do Terminal (ou Prompt) do seu
                sistema operacional.
              </p>
            </div>
          </div>

          <div className="w-full">
            <h2 className="mt-4 mb-4 font-bold text-blue-500 text-xl">
              Índice
            </h2>
          </div>
          <div className="w-full">
            <ol className="list-inside list-decimal">
              <Link to="introduction">
                <li className="text-blue-500">Introdução</li>
              </Link>
              <Link to="first-program-print-comments-and-data-types">
                <li className="text-blue-500">
                  Primeiro Programa - Print, Comentários e Tipos de Dados
                </li>
              </Link>
              <Link to="mathematical-operators-and-signs">
                <li className="text-blue-500">
                  Sinais e operações matemáticas
                </li>
              </Link>
              <Link to="variables">
                <li className="text-blue-500">Variáveis</li>
              </Link>
              <Link to="string-text">
                <li className="text-blue-500">Strings (dado em texto)</li>
              </Link>
              <Link to="lists">
                <li className="text-blue-500">Listas</li>
              </Link>
              <Link to="tuples">
                <li className="text-blue-500">Tuplas</li>
              </Link>
              <Link to="dictionaries">
                <li className="text-blue-500">Dicionários</li>
              </Link>
              <Link to="sets">
                <li className="text-blue-500">Sets</li>
              </Link>
              <Link to="control-structures">
                <li className="text-blue-500">Estruturas de Controle</li>
              </Link>
              <li>Funções</li>
              <li>Módulos</li>
              <li>Classes e Programação Orientada a Objetos</li>
              <li>Data e Tempo</li>
              <li>Exceções</li>
              <li>Arquivos</li>
              <li>Palavras Finais</li>
            </ol>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    basicPythonCover: file(absolutePath: { regex: "/capa-livro-python.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default Books
